<template>
  <div>
    <main-tab-bar
      @handleTabClick="handleTabClick"
      @closeTab="closeTab"
      :loading="showTabLoading"
      :activename="activename"
      :tabitem="tabitem"
      ref="tabbar"
    >
      <!-- 固定的插槽名称需要和tabitem的label对应  v-if="tableColumn.length" -->
      <div :slot="currentNav.name">
        <!-- 头部区域 -->
        <el-row type="flex" class="row-bg top-box" justify="space-between">
          <el-col :span="6">
            <el-button size="mini" type="primary" @click="handleAdd"
              >新增</el-button
            >
          </el-col>
          <!-- 模块配置新增 -->
          <el-form v-if="currentNav.index == 'amt_module'" class="radio">
            <el-radio-group v-model="radio" @change="radioChange">
              <el-radio :label="-1">全部</el-radio>
              <el-radio :label="0">区域模块</el-radio>
              <el-radio :label="1">功能模块</el-radio>
            </el-radio-group>
          </el-form>
          <!-- 链接配置新增 -->
          <el-form v-if="currentNav.index == 'amt_linkconfig'" class="radio">
            <el-radio-group v-model="linkRadio" @change="linkRadioChange">
              <el-radio :label="0">外部链接</el-radio>
              <el-radio :label="1">功能模块</el-radio>
            </el-radio-group>
          </el-form>
          <!-- 酒店列表配置 -->
          <el-form v-show="currentNav.index == 'room'" class="hotel_config">
            <el-form-item label="酒店：">
              <el-select
                v-model="hotelName"
                placeholder="全部"
                size="mini"
                clearable
              >
                <el-option
                  v-for="item in hotels"
                  :key="item.row_id"
                  :label="item.name"
                  :value="item.row_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="类型：">
              <el-select
                v-model="hotelType"
                placeholder="全部"
                size="mini"
                clearable
              >
                <el-option
                  v-for="item in selectType"
                  :key="item.row_id"
                  :label="item.name"
                  :value="item.row_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>

          <!-- 查询 start -->
          <el-form @submit.native.prevent :inline="true" class="search">
            <el-form-item label="名称：">
              <el-input
                size="mini"
                @keyup.enter.native="onQuery"
                clearable
                v-model="searchKey"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                size="mini"
                type="primary"
                @click="onQuery"
                icon="el-icon-search"
                >查询</el-button
              >
            </el-form-item>
          </el-form>
          <!-- 查询 end -->
        </el-row>

        <!-- 列表内容 -->
        <table-comp
          :table-data="tableData"
          :table-column="tableColumn"
          :pagenum="pageNum"
          :total="pagetotal"
          :pageno="pageNo"
          :show-batch-del="true"
          :show-export="false"
          :loading="showTableLoading"
          :hotels="hotels"
          :selectType="selectType"
          @batchDel="batchDel"
          @handlePageChange="handlePageChange"
          @handleSizeChange="handleSizeChange"
          @editRow="editRow"
          @delRow="delRow"
          @editRowStyle="editRowStyle"
        ></table-comp>
      </div>

      <!-- 编辑页插槽 -->
      <div :slot="tabEditLabel">
        <from-data
          :show-filed-data="showFieldData"
          :selectdata="selectData"
          :form="form"
          :is-edit="isEdit"
          :rules="rules"
          @closetForm="closetForm"
          @submitForm="submitForm"
        ></from-data>
      </div>

      <!-- 风格页插槽 -->
      <div :slot="tabStyleLabel">
        <el-row
          type="flex"
          class="row-bg top-box"
          justify="space-between"
          v-if="tableColumn.length"
        >
          <el-col :span="6">
            <el-button size="mini" type="primary" @click="handleStyleAdd"
              >新增</el-button
            >
          </el-col>

          <el-form @submit.native.prevent :inline="true" class="search">
            <el-form-item label="名称：">
              <el-input
                size="mini"
                @keyup.enter.native="onQuery"
                clearable
                v-model="searchKey"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                size="mini"
                type="primary"
                @click="onQuery"
                icon="el-icon-search"
                >查询</el-button
              >
            </el-form-item>
          </el-form>
        </el-row>
        <table-comp
          :table-data="tableStyleData"
          :table-column="tableStyleColumn"
          :pagenum="pageNum"
          :total="pagetotal"
          :pageno="pageNo"
          :show-batch-del="true"
          :show-export="false"
          :loading="showTableLoading"
          @batchDel="batchDel"
          @handlePageChange="handlePageChange"
          @handleSizeChange="handleSizeChange"
          @editRow="editRow"
          @delRow="delRow"
          @editRowStyle="editRowStyle"
          @editStyle="editStyle"
        ></table-comp>
      </div>
      <!-- 风格编辑 -->
      <div :slot="tabStyleEdit">
        <div class="style-edit-form">
          <el-form
            :model="styleEditForm"
            label-position="right"
            label-width="120px"
            size="mini"
            status-icon
            style="width: 65%"
            :rules="styleRules"
            ref="styleEditForm"
          >
            <el-row :gutter="20">
              <el-col :sm="24" :md="24" :lg="24" :xl="24" :offset="0">
                <!-- 小程序标题 start @author: HDZ  !-->
                <el-form-item label="名称：" prop="name">
                  <el-input
                    v-model="styleEditForm.name"
                    placeholder="请输入风格名称"
                  ></el-input>
                </el-form-item>
                <el-form-item label="标识：" prop="identify">
                  <el-input v-model="styleEditForm.identify"></el-input>
                </el-form-item>
                <el-form-item label="图片：" prop="image">
                  <ImageUpload
                    :imgsrc="styleEditForm.image"
                    source="styleEdit"
                    filedname="image"
                  ></ImageUpload>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item>
              <el-button
                type="primary"
                @click="clickSave('styleEditForm')"
                :disabled="styleDisabled"
                >保存</el-button
              >
              <el-button type="primary" @click="closetForm('styleEdit')"
                >关闭</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </main-tab-bar>
  </div>
</template>

<script>
//标签页组件
import MainTabBar from "@components/content/maintabbar/MainTabBar";
//表格组件
import TableComp from "@components/content/tablecomp/TableComp";
//表单组件
import FromData from "./childecomponent/FromData";
import ImageUpload from "./childecomponent/ImageUpload.vue";

import { isNum } from "@common/rules";

export default {
  components: {
    MainTabBar,
    TableComp,
    FromData,
    ImageUpload,
  },
  data() {
    return {
      // 记录模块配置新增模块的字段，配合解决一些bug
      modulename: "",
      tabitem: [
        {
          name: "sjy", //移除时使用
          label: "",
        },
      ],
      radio: -1,
      linkRadio: 0,
      //table
      tableData: [],
      tableColumn: [], //表格的栏目
      tableStyleData: [],
      tableStyleColumn: [],
      pageNum: 20,
      pagetotal: 0,
      pageNo: 1,
      tabEditLabel: "",
      activename: "",
      typeList: [],
      showTableLoading: false, //表格加载中
      showTabLoading: false, //标签页加载中
      isEdit: false, //
      currentNav: {},
      fieldData: [],
      showFieldData: [],
      form: {},
      selectData: {},
      fieldMap: {},
      styleRules: {
        name: { required: true, message: "请输入名称", trigger: "blur" },
        identify: {
          required: true,
          message: "请输入标识",
          trigger: "blur",
        },
        image: {
          required: false,
          message: "请上传样式风格",
          trigger: "blur",
        },
      },
      rules: {},
      searchKey: "", //搜索关键字
      //配置需要根据id 转成文字的字段
      idToName: ["play_id", "payment", "parent_id", "modul_id"],
      directory_code: null,
      tabStyleLabel: "", //风格
      tabStyleEdit: "",

      // 风格表单
      styleEditForm: {
        name: "",
        identify: "",
        module_id: null,
        image: "",
      },
      current_module_id: null, // 当前选中的模块
      styleDisabled: false,
      record_current_e: -1,
      storeImg: null,
      fromDataC: null,
      hotels: [],
      hotelType: "", //酒店类型
      hotelName: "", //酒店
      selectType: [],
      defiRules: [
        "求助人",
        "评论标题",
        "标题",
        "电话号码",
        "姓名",
        "中文名称",
        "名称",
        "中文名",
      ],
      filter_code: ["amuse", "pro_owner", "commodity", "play"],
      filter_code2: ["ticket", "consultation", "complaint_case"],
      currentParams: {},
    };
  },
  created() {
    this.$bus.$off("imgUploaded", this.imgUploaded);
    this.$bus.$off("imgRemoved", this.imgRemoved);

    this.$bus.$off("imgsUploaded", this.imgsUploaded);
    this.$bus.$off("imgsRemoved", this.imgsRemoved);

    // this.$set(this.storeImg,"this.storeImg",this.fromDataC.images)
  },
  mounted() {
    this.activename = "sjy";
    this.currentNav = this.$store.state.currentNav;
    this.current_module_id = this.$store.state.current_module_id;

    this.$set(this.tabitem[0], "label", this.currentNav.name);

    this.getResourcefield();
    //接收事件总线
    //单图
    this.$bus.$on("imgUploaded", this.imgUploaded);
    this.$bus.$on("imgRemoved", this.imgRemoved);
    //多图
    this.$bus.$on("imgsUploaded", this.imgsUploaded);
    this.$bus.$on("imgsRemoved", this.imgsRemoved);

    //风格栏目
    this.tableStyleColumn = [
      {
        row_id: 1,
        label: "名称",
        prop: "name",
        fristEnten: true,
        func: "editStyle",
      },
      {
        row_id: 2,
        label: "对应标识",
        prop: "identify",
      },
    ];
    this.tableStyleColumn.push({
      label: "操作",
      //是否是操作
      isAction: true,
      fixed: "right",
      //操作的按钮与方法
      actionItem: [
        {
          text: "编辑", //按钮的文本
          icon: "el-icon-edit",
          type: "primary", //按钮类型
          func: "editRow", //按钮的方法
        },
      ],
      //带确认的按钮
      popconfirmitem: [
        {
          text: "删除",
          icon: "el-icon-delete",
          func: "delRow",
          title: "确定删除这条数据吗？",
        },
      ],
    });
  },
  beforeDestroy() {
    this.$store.commit("setCurrentModuleId", null);
  },
  computed: {
    listenCurrentNav() {
      //监听点击菜单
      this.searchKey = "";
      this.radio = -1;
      this.linkRadio = 0;
      return this.$store.state.currentNav;
    },
  },
  watch: {
    //监听点击菜单
    listenCurrentNav(val) {
      //dom更新后
      this.$nextTick(() => {
        this.$set(this.tabitem[0], "label", val.name);
      });
      // console.log(this.form);
      this.currentNav = val;
      this.getResourcefield();
      this.closetForm("editpage", "resetTabitem");
    },

    form: {
      deep: true,
      handler(newval) {
        this.storeImg = this.form.images
          ? JSON.parse(JSON.stringify(this.form.images))
          : [];
        // 只有在新增页面执行
        // 在链接配置里，当选择外部链接或者功能模块时，form表单字段要变化
        if (newval.type == 0) {
          if (!this.isEdit) {
            this.$set(this.form, "module_id", "");
            this.$set(this.form, "style", "");
          }
          //  this.$delete(this.style,'module_id')
          this.showFieldData = this.fieldData.filter((val) => {
            return (
              val.show_type != "0" &&
              val.name !== "风格" &&
              val.name !== "模块id"
            );
          });
        } else if (newval.type == 1) {
          this.showFieldData = this.fieldData.filter((val) => {
            return val.show_type != "0";
          });
          // 功能模块和样式进行拼接
          if (newval.module_id && newval.style) {
            // this.form.url=''
            let { identify } = this.selectData.module_id.find(
              (module) => module.row_id === newval.module_id
            );
            this.$set(this.form, "url", `/pages/${identify}/${newval.style}`);
          } else {
            // this.form.url = "";
          }
        }
      },
    },
  },
  methods: {
    //获取资源字段
    getResourcefield() {
      this.showTabLoading = true;
      this.pageNo = 1;
      let params = {
        directory_code: this.currentNav.index,
      };

      this.$Request({
        method: "get",
        url: this.$Api.getResourcefield,
        params,
      })
        .then((result) => {
          // console.log("获取资源字段", result);
          this.fieldData = result.data.list;
          this.showFieldData = this.fieldData.filter((val) => {
            return val.show_type != "0";
          });

          // console.log(this.showFieldData)

          if (
            this.fieldData == 0 ||
            this.fieldData[0].code != this.currentNav.index
          ) {
            this.tableData = [];
            this.tableColumn = [];
            this.showTabLoading = false;
          } else {
            this.handleSelectData();
            this.pagetotal = result.total;
          }
        })
        .catch((err) => {});
    },

    //区域模块/功能模块
    radioChange(e) {
      this.record_current_e = e;
      if (e == -1) {
        this.queryDataOfResource(); //查询全部的数据
      } else {
        let data = {
          type: e + "",
          // is_check: 1
        };
        this.queryDataOfResource(data);
      }
    },
    linkRadioChange(e) {
      let data = {
        type: e + "",
        // is_check: 1
      };
      this.queryDataOfResource(data);
    },
    //点击查询按钮
    onQuery() {
      if (this.current_module_id == null) {
        if (this.currentNav.index == "room") {
          let data = {
            stay_id: this.hotelName,
            type: this.hotelType,
            title: this.searchKey,
          };
          Object.keys(data).forEach((val) => {
            if (data[val] === "") delete data[val];
          });
          this.queryDataOfResource(data);
        } else {
          let correctCode = this.filter_code.includes(this.currentNav.index);
          let correctCode2 = this.filter_code2.includes(this.currentNav.index);

          if (correctCode) {
            this.currentParams = {
              name_chn: this.searchKey,
              // is_check: 1,
            };
          } else if (correctCode2) {
            this.currentParams = {
              title: this.searchKey,
              // is_check: 1,
            };
          } else {
            this.currentParams = {
              name: this.searchKey,
              // is_check: 1,
            };
          }
          this.queryDataOfResource(this.currentParams);
        }

        // }
      } else {
        this.queryDataOfStyle();
      }
    },
    //获取表格数据
    queryDataOfResource(
      data = { [this.tableColumn[0]?.prop]: this.searchKey } //this.searchKey 搜索的关键字 this.tableColumn[0].prop
    ) {
      if (!this.showTabLoading) {
        this.showTabLoading = true;
      }
      console.log([this.tableColumn[0].prop]);
      let params = {};
      if (this.currentNav.index == "amt_linkconfig") {
        let res = JSON.parse(sessionStorage.getItem("userInfo"));
        data = { ...data, type: this.linkRadio };
        params = {
          data: data,
          pageNo: this.pageNo,
          pageNum: this.pageNum,
          directory_code: this.currentNav.index,
          destination_id: res.destination_id,
        };
      } else {
        params = {
          data: data,
          pageNo: this.pageNo,
          pageNum: this.pageNum,
          directory_code: this.currentNav.index,
        };
      }

      this.$Request({
        method: "get",
        url: this.$Api.queryDataOfResource,
        params,
      })
        .then((result) => {
          if (result.directory_code == this.currentNav.index) {
            this.directory_code = result.directory_code;
            result.data.forEach((i) => {
              if (i.type == 1) {
                i.chineseN = "功能";
              } else {
                i.chineseN = "区域";
              }
            });
            this.tableData = result.data;
            console.log("表格数据", result.data);
            this.pagetotal = result.total;
            this.showTabLoading = false;
          } else {
            this.showTabLoading = false;
          }
        })
        .catch((err) => {
          this.showTabLoading = false;
        });
    },
    // 获取模块的风格数据
    queryDataOfStyle() {
      if (!this.showTabLoading) {
        this.showTabLoading = true;
      }
      let params = {
        data: {
          [this.tableStyleColumn[0].prop]: this.searchKey,
          module_id: this.current_module_id,
          searchmodel: "exact",
        },

        pageNo: this.pageNo,
        pageNum: this.pageNum,
        directory_code: "amt_styleconfig",
      };
      this.$Request({
        method: "get",
        url: this.$Api.queryDataOfResource,
        params,
      })
        .then((res) => {
          // console.log('获取风格的数据',res);
          this.tableStyleData = res.data;
          this.pagetotal = res.total;
          this.showTabLoading = false;
        })
        .catch((err) => {
          this.showTabLoading = false;
        });
    },

    getMap() {
      let fieldData = [...this.showFieldData];
      // console.log(fieldData.length)
      fieldData.forEach((item, index) => {
        if (
          index + 1 < fieldData.length &&
          fieldData[index + 1].name != "" &&
          item.en_name == "latitude"
        ) {
          //this.fielddata.push({name:" ",show_type:11})
          this.showFieldData.splice(index + 1, 0, {
            name: "",
            show_type: 11,
          });
          // console.log('这个页面可以添加经纬度')
        } else if (index + 1 > fieldData.length && item.en_name == "latitude") {
          this.showFieldData.push({ name: "", show_type: 11 });
          // console.log('这个页面可以添加经纬度')
        }
      });
    },

    //处理显示的字段
    handleColumn(dataField) {
      console.log(dataField);
      dataField.forEach((item) => {
        if (item.name == "酒店id") {
          item.is_intable = "1";
        } else if (item.name == "房间类型") {
          item.is_intable = "1";
        } else if (item.en_name == "parent_id") {
          item.is_intable = "0";
        }
      });
      // console.log(dataField) //获取的是资源字段数据
      this.tableColumn = [];
      let showColumn = dataField.filter((val) => {
        //过滤掉dataField中is_intable为1的，然后作为表格的栏目
        return val.is_intable == "1";
      });
      // console.log(showColumn)
      showColumn.forEach((element, index) => {
        let filedObj = {
          label: "",
          prop: "",
          show_type:''
        };
        filedObj.label = element.name;
        filedObj.prop = element.en_name;
        filedObj.show_type=element.show_type
        if (index == 0) {
          filedObj.fristEnten = true; //首列点击
          filedObj.func = "editRow"; //点击的方法
        }

        // 先访问括号内的运算
        if (
          this.idToName.includes(element.en_name) &&
          (element.show_type == "2" || element.show_type == "4")
        ) {
          //false
          let maparr = [],
            dictionary = this.selectData[element.en_name];
          if (dictionary) {
            dictionary.forEach((item) => {
              maparr.push([item.row_id, item.name]);
            });
            filedObj.map = new Map(maparr);
          }
        }
        // console.log(filedObj)
        this.tableColumn.push(filedObj);
      });
      if (this.currentNav.index == "amt_module") {
        this.tableColumn.push(
          {
            label: "操作",
            //是否是操作
            isAction: true,
            fixed: "right",
            //操作的按钮与方法
            actionItem: [
              {
                text: "编辑", //按钮的文本
                icon: "el-icon-edit",
                type: "primary", //按钮类型
                func: "editRow", //按钮的方法
              },
              {
                text: "风格编辑", //按钮的文本
                icon: "el-icon-s-operation",
                type: "primary", //按钮类型
                func: "editRowStyle", //按钮的方法
              },
            ],
            //带确认的按钮
            popconfirmitem: [
              {
                text: "删除",
                icon: "el-icon-delete",
                func: "delRow",
                title: "确定删除这条数据吗？",
              },
            ],
          },
          {
            label: "模块类型",
            //是否是操作
            isAction: false,
            prop: "chineseN",
          }
        );
      }else if(this.currentNav.index == "ticket"){
        this.tableColumn.push(
          {
            label: "操作",
            //是否是操作
            isAction: true,
            fixed: "right",
            //操作的按钮与方法
            actionItem: [
              {
                text: "编辑", //按钮的文本
                icon: "el-icon-edit",
                type: "primary", //按钮类型
                func: "editRow", //按钮的方法
              },
              {
                text: "价格日历编辑", //按钮的文本
                icon: "el-icon-date",
                type: "primary", //按钮类型
                func: "openCalender",
              },
            ],
            //带确认的按钮
            popconfirmitem: [
              {
                text: "删除",
                icon: "el-icon-delete",
                func: "delRow",
                title: "确定删除这条数据吗？",
              },
            ],
          }
        );
      } else {
        this.tableColumn.push({
          label: "操作",
          //是否是操作
          isAction: true,
          fixed: "right",
          //操作的按钮与方法
          actionItem: [
            {
              text: "编辑", //按钮的文本
              icon: "el-icon-edit",
              type: "primary", //按钮类型
              func: "editRow", //按钮的方法
            },
          ],
          //带确认的按钮
          popconfirmitem: [
            {
              text: "删除",
              icon: "el-icon-delete",
              func: "delRow",
              title: "确定删除这条数据吗？",
            },
          ],
        });
      }
    },
    //处理遍历fromdata数据
    handleSelectData() {
      // 写一个正则是否包含
      let reg = /dict=/;
      //初始化rules
      // console.log(this.rules)
      Object.keys(this.rules).forEach((key) => {
        this.rules[key] = "";
      });

      // showFieldData为资源字段
      this.showFieldData.forEach(async (item, index) => {
        // 为模块类型专门设置一个show_type
        if (item.code == "amt_module" && item.name == "类型") {
          item.show_type = "22";
        }
        //获取字段类型map 在判断是那种数据类型中使用
        this.fieldMap[item.en_name] = item.type;

        if (item.show_type == 4 && !item.associate) {
          if (item.code == "amt_linkconfig" && item.name == "模块id") {
            let params = {
              data: { type: 1 },
              directory_code: "amt_module", //传值的关键,
            };
            let res = await this.$Request({
              method: "get",
              url: this.$Api.queryDataOfResource,
              params,
            });
            this.selectData[item.en_name] = res.data;
          } else if (item.code == "amt_linkconfig" && item.name == "风格") {
            this.selectData[item.en_name] = [
              {
                name: "默认",
                row_id: "basicStyle",
              },
              {
                name: "风格2",
                row_id: "style2",
              },
              {
                name: "风格3",
                row_id: "style3",
              },
              {
                name: "风格4",
                row_id: "style4",
              },
              {
                name: "风格5",
                row_id: "style5",
              },
              {
                name: "风格6",
                row_id: "style6",
              },
              {
                name: "风格7",
                row_id: "style7",
              },
              {
                name: "风格8",
                row_id: "style8",
              },
            ];
          } else if (item.en_name == "sex") {
            this.selectData[item.en_name] = [
              {
                name: "男",
                row_id: 1,
              },
              {
                name: "女",
                row_id: 0,
              },
            ];
          }

          // console.log('执行1')
        } else if (item.show_type == 2 && !item.associate) {
          if (item.code == "amt_linkconfig" && item.name == "类型") {
            this.selectData[item.en_name] = [
              {
                name: "功能模块",
                row_id: 1,
              },
              {
                name: "外部链接",
                row_id: 0,
              },
            ];
          } else if (item.code == "consultation" && item.name == "是否入库") {
            this.selectData[item.en_name] = [
              {
                name: "是",
                row_id: 1,
              },
              {
                name: "否",
                row_id: -1,
              },
            ];
          } else {
            this.selectData[item.en_name] = [
              {
                name: "是",
                row_id: 1,
              },
              {
                name: "否",
                row_id: 0,
              },
            ];
          }
        } else if (
          item.associate &&
          !reg.test(item.associate) &&
          (item.show_type == 2 ||
            item.show_type == 3 ||
            item.show_type == 4 ||
            item.show_type == 9)
        ) {
          this.queryDataOfResourceForSelect(item.en_name, item.associate);
          // console.log('执行2')
        } else if (
          item.associate &&
          reg.test(item.associate) &&
          item.show_type == 4
        ) {
          // dict={\"1\":\"商品\",\"2\":\"虚拟物品\"}转换成对象形式{name:'商品',row_id:1}的形式
          let dict = JSON.parse(item.associate.replace("dict=", ""));
          let arr = [];
          for (let key in dict) {
            arr.push({
              name: dict[key],
              row_id: key,
            });
          }
          this.selectData[item.en_name] = arr;
        }
        //向字段中添加rule
        let ruleItem;
        if (item.type.indexOf("int") != -1) {
          if (item.code == "amt_linkconfig" && item.name == "风格") {
          } else {
            ruleItem = { validator: isNum, trigger: "blur" };
            this.rules[item.en_name] = [];
            this.rules[item.en_name].push(ruleItem);
          }
        }
        if (
          item.show_type == 1 &&
          this.currentNav.index == "coupon" &&
          item.name == "减金额"
        ) {
          let validatePrice = (rule, value, callback) => {
            if (value === "") {
              callback(new Error("请输入金额"));
            } else if (value > this.form.aprice) {
              callback(new Error("减金额不能大于满多元可用!"));
            } else {
              callback();
            }
          };
          ruleItem = { validator: validatePrice, trigger: "blur" };
          this.rules[item.en_name] = [];
          this.rules[item.en_name].push(ruleItem);
        }
        // this.rulesName.includes(item.name)

        if (item.restraint == 1 || this.defiRules.includes(item.name)) {
          ruleItem = {
            required: true,
            message: "请输入" + item.name,
            trigger: "blur",
          };
          if (item.show_type == 4) {
            ruleItem = {
              required: true,
              message: "请选择" + item.name,
              trigger: "blur",
            };
          } else if (item.show_type == 6) {
            ruleItem = {
              required: false,
              message: "请上传图片",
              trigger: "blur",
            };
          }

          this.rules[item.en_name] = [];
          this.rules[item.en_name].push(ruleItem);
        }
      });

      this.handleColumn(this.fieldData);
      if (
        this.modulename == "editpage" &&
        this.currentNav.index == "amt_module"
      ) {
        let data = {
          type: this.radio + "",
        };
        if (this.radio == -1) {
          this.queryDataOfResource();
        } else {
          this.queryDataOfResource(data);
        }
      } else {
        this.queryDataOfResource();
      }
    },

    //查询资源对应数据用于选择框
    queryDataOfResourceForSelect(fieldName, resourceCode) {
      let params = {
        // data: { is_check: 1 },
        pageNo: this.pageNo,
        pageNum: 999999,
        directory_code: resourceCode,
      };

      this.$Request({
        method: "get",
        url: this.$Api.queryDataOfResource,
        params,
      })
        .then((res) => {
          if (res.errcode == 0) {
            this.selectData[fieldName] = [];
            this.selectData[fieldName] = res.data;
            if (res.directory_code == "roomtype") {
              this.selectType = [];
              this.selectType = res.data;
            }
            if (res.directory_code == "stay") {
              this.hotels = [];
              this.hotels = res.data;
            }
            // this.typeData[fieldName] = {};
            // for (const i of this.selectData[fieldName]) {
            //   this.typeData[fieldName][i.row_id] = i.name;
            // }
            this.handleColumn(this.fieldData);
          }
        })
        .catch((err) => {
          this.showTabLoading = false;
        });
    },

    //分页
    handlePageChange(val) {
      this.pageNo = val;
      console.log("分页切换");
      if (this.activename == "sjy") {
        if (this.radio == -1) {
          this.queryDataOfResource();
        } else {
          let data = {
            type: this.radio + "",
            // is_check: 1
          };
          this.queryDataOfResource(data);
        }
      } else if (this.activename == "stylePage") {
        this.queryDataOfStyle();
      }

      // if (this.current_module_id == null) {
      //   if (this.radio == -1) {
      //     this.queryDataOfResource();
      //   } else {
      //     let data = { type: this.radio + "", is_check: 1 };
      //     this.queryDataOfResource(data);
      //   }
      // } else {
      //   this.queryDataOfStyle();
      // }
    },

    //切换分页大小
    handleSizeChange(val) {
      // console.log('1')
      this.pageNum = val;
      this.queryDataOfResource();
    },
    //点击编辑
    editRow(row, field) {
      console.log(row);

      this.selectType.forEach((item1) => {
        for (let item2 in row) {
          if (row[item2] == item1.name) {
            row.type = item1.row_id;
          }
        }
      });

      this.hotels.forEach((item1) => {
        for (let item2 in row) {
          if (row[item2] == item1.name) {
            row.stay_id = item1.row_id;
          }
        }
      });

      this.form = JSON.parse(JSON.stringify(row));

      if (this.form.images) {
        this.form.images = JSON.parse(this.form.images) || [];
      }

      this.getMap();
      //点击编辑时标签页title
      this.tabEditLabel = row[field] + "-编辑";
      this.activename = "editpage";
      this.$refs.tabbar.activeName = "editpage";
      this.isEdit = true;
      if (!this.isIncludes(this.activename)) {
        this.tabitem.push({
          name: "editpage",
          label: this.tabEditLabel,
          closable: true,
        });
      } else {
        this.tabitem[1].label = this.tabEditLabel;
      }
    },

    // 点击风格编辑
    editStyle(row, field) {
      // console.log('点击风格编辑',row)
      this.styleEditForm = JSON.parse(JSON.stringify(row));
      this.tabStyleEdit = "风格编辑";
      this.activename = "styleEdit";
      this.$refs.tabbar.activeName = "styleEdit";
      this.isEdit = true;
      if (!this.isIncludes(this.activename)) {
        //没打开就添加一个
        this.tabitem.push({
          name: "styleEdit",
          label: this.tabStyleEdit,
          closable: true,
        });
      } else {
        this.tabitem[1].label = this.tabStyleEdit; //打开就直接变个标题 合理！这个想法恒河里
      }
    },

    // 点击风格进入风格页
    editRowStyle(row, field) {
      // 点击后把当前的module_id存到 vuex中
      this.$store.commit("setCurrentModuleId", row.row_id);
      this.current_module_id = this.$store.state.current_module_id;
      this.queryDataOfStyle();

      // console.log("风格", row, field);
      this.getMap();
      //点击编辑时标签页title
      this.tabStyleLabel = row[field] + "-风格";
      this.activename = "stylePage";
      this.$refs.tabbar.activeName = "stylePage";

      if (!this.isIncludes(this.activename)) {
        //判断标签页是否打开
        this.tabitem.push({
          name: "stylePage",
          label: this.tabStyleLabel,
          closable: true,
        });
      } else {
        this.tabitem[1].label = this.tabStyleLabel;
      }
    },
    //点击删除
    delRow(row) {
      this.deleteData(row);
    },
    //批量删除
    batchDel(row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let id = this.$store.state.current_module_id;
          let row_ids = row
            .map((val) => {
              return val.row_id;
            })
            .toString();
          let params = {
            data: {
              row_id: JSON.parse(JSON.stringify(row_ids)),
            },
            directory_code:
              id == null ? this.currentNav.index : "amt_styleconfig",
          };
          this.$Request({
            method: "get",
            url: this.$Api.deleteDataOfResource,
            // headers: {'Content-Type': 'application/json'},
            params,
          }).then((res) => {
            console.log(res);
            if (res.errCode == 0) {
              this.$message({
                showClose: true,
                message: "数据删除成功",
                type: "success",
              });
              if (this.current_module_id == null) {
                console.log("我执行了吗");
                this.queryDataOfResource();
              } else {
                this.queryDataOfStyle();
              }
            } else {
              // this.$message.error(res.errMsg);
            }
          });
        })
        .catch(() => {
          this.$message({ type: "info", message: "已取消删除" });
        });
    },
    //点击添加按钮
    handleAdd() {
      this.getMap();
      this.form = {};
      this.tabEditLabel = "新增";
      this.activename = "editpage";
      this.$refs.tabbar.activeName = "editpage";

      this.isEdit = false;
      // 单选框默认值
      for (const i of this.showFieldData) {
        if (!this.isEdit) {
          if (i.show_type == "2" && !i.associate) {
            this.form = { ...this.form, [i.en_name]: 1 };
          }
        }
      }
      if (!this.isIncludes(this.activename)) {
        //false，标签页没打开
        //没打开就添加一个
        this.tabitem.push({
          name: "editpage",
          label: this.tabEditLabel,
          closable: true,
        });
      } else {
        this.tabitem[1].label = this.tabEditLabel; //打开就直接变个标题 合理！这个想法恒河里
      }
    },
    // 点击添加风格按钮
    handleStyleAdd() {
      this.getMap();
      this.tabStyleEdit = "新增风格";
      this.activename = "styleEdit";
      this.$refs.tabbar.activeName = "styleEdit";
      this.isEdit = false;
      if (!this.isIncludes(this.activename)) {
        //没打开就添加一个
        this.tabitem.push({
          name: "styleEdit",
          label: this.tabStyleEdit,
          closable: true,
        });
      } else {
        this.tabitem[1].label = this.tabStyleEdit; //打开就直接变个标题 合理！这个想法恒河里
      }
    },
    //点击标签页
    handleTabClick(tab) {
      this.activename = tab.name;
      // console.log(this.activename)
      // if (tab.name == "sjy") {
      //   this.queryDataOfResource();
      // } else {
      //   this.queryDataOfStyle();
      // }
    },
    //关闭标签页
    closeTab(name) {
      if (this.$store.state.current_module_id == null) {
        // if (this.$store.state.currentNav.index == "amt_module") {
        //   if (this.record_current_e == -1) {
        //     this.queryDataOfResource(); //查询全部的数据
        //   } else if (name == "editpage") {
        //     let data = {
        //       type: this.record_current_e + "",
        //       //  is_check: 1
        //     };
        //     this.queryDataOfResource(data); //查询全部的数据
        //   } else {
        //     let data = {
        //       type: this.record_current_e + "",
        //       //  is_check: 1
        //     }; //区域查询
        //     this.queryDataOfResource(data);
        //   }
        // } else {
        //   this.queryDataOfResource();
        // }
      }

      this.activename = "sjy";

      for (const key in this.form) {
        this.form[key] = "";
      }
      for (const key in this.styleEditForm) {
        this.styleEditForm[key] = "";
      }
    },
    //点击关闭按钮
    // ? 无法读取到数据
    closetForm(tab = "editpage", isReset) {
      this.form = {};
      this.$refs["tabbar"].removeTab(tab, isReset);
      this.activename = "sjy";
      if (tab != "editpage") {
        this.activename = "stylePage";
        this.$refs.tabbar.activeName = "stylePage";
      }
      // this.queryDataOfResource(); //查询全部的数据
    },
    //点击保存
    submitForm(fromData) {
      // let fromDataC = this.clearSubmitData(fromData);

      this.fromDataC = JSON.parse(
        JSON.stringify(this.clearSubmitData(fromData))
      );
      // fromDataC.images = fromDataC.images ? JSON.stringify(fromDataC.images) : ""
      console.log(this.fromDataC);
      if (this.isEdit) {
        this.modifyData(this.fromDataC);
      } else {
        this.addData(this.fromDataC);
      }
    },
    // 点击风格保存
    clickSave(formName) {
      this.styleDisabled = true;
      setTimeout(() => {
        this.styleDisabled = false;
      }, 3000);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let formDataC = this.clearSubmitData(this.styleEditForm);
          formDataC.module_id = this.current_module_id;
          // console.log("formDataC", formDataC);
          if (this.isEdit) {
            this.modifyData(formDataC, "amt_styleconfig");
          } else {
            this.addData(formDataC, "amt_styleconfig",'style');
          }
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    //处理上传前的表单  int类型 为空都写为0 删除create_time
    clearSubmitData(fromData) {
      // console.log(fromData);
      delete fromData.create_time;
      //删除空字段
      Object.keys(fromData).map((item) => {
        let isint = false;
        if (this.fieldMap.hasOwnProperty(item)) {
          isint = this.fieldMap[item].indexOf("int") != -1;
        }
        if (isint && fromData[item] === "") {
          fromData[item] = 0;
        }
        return true;
      });
      return fromData;
    },
    //修改数据
    modifyData(fromData, dc = this.currentNav.index) {
      let params = { data: fromData, directory_code: dc };
      this.$Request({
        method: "post",
        url: this.$Api.modifyDataOfResource,
        // headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        // headers: {'Content-Type': 'application/json'},
        data: params,
      })
        .then((result) => {
          if (result.errCode == 0) {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            console.log(this.currentNav.name, this.activename);
            if (
              this.currentNav.index == "amt_module" &&
              this.activename == "editpage"
            ) {
              this.radioChange(this.radio);
            } else if (
              this.currentNav.index == "amt_module" &&
              this.activename == "styleEdit"
            ) {
              this.queryDataOfStyle();
            } else {
              this.queryDataOfStyle();
              this.queryDataOfResource();
            }
            if (this.$store.state.current_module_id != null) {
              this.closetForm("styleEdit");
            } else {
              this.closetForm();
            }
          }
        })
        .catch((err) => {});
    },
    //添加数据
    addData(fromData, dc = this.currentNav.index,style) {
      let params = {};
      if (this.currentNav.index == "amt_linkconfig") {
        let res = JSON.parse(sessionStorage.getItem("userInfo"));
        params = {
          data: fromData,
          directory_code: dc,
          destination_id: res.destination_id,
        };
      } else {
        params = { data: fromData, directory_code: dc };
      }
      console.log(params);
      this.$Request({
        method: "post",
        url: this.$Api.addDataOfResource,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: params,
      })
        .then((result) => {
          console.log(result);
          if (result.errCode == 0) {
            this.$message({
              message: "新增成功",
              type: "success",
            });
            if (
              this.activename == "editpage" &&
              this.currentNav.index == "amt_module"
            ) {
              this.modulename = "editpage";
            } else {
              this.modulename = "";
            }
            if(style=='style'){
            this.queryDataOfStyle();
            }else{
            this.getResourcefield(); //获取资源字段

            }
            if (this.$store.state.current_module_id != null) {
              this.closetForm("styleEdit");
            } else {
              this.closetForm();
            }
          }
        })
        .catch((err) => {});
    },
    //删除数据
    deleteData(row) {
      console.log(row);
      let id = this.$store.state.current_module_id;
      let params = {
        data: { row_id: row.row_id },
        directory_code: id == null ? this.currentNav.index : "amt_styleconfig",
      };
      this.$Request({
        method: "get",
        url: this.$Api.deleteDataOfResource,
        // headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        // headers: {'Content-Type': 'application/json'},
        params,
      })
        .then((result) => {
          console.log(result);
          if (result.errCode == 0) {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            if (
              this.currentNav.index == "amt_module" &&
              this.activename == "stylePage"
            ) {
              this.queryDataOfStyle();
            } else {
              if (this.currentNav.index != "comment") {
                this.queryDataOfStyle();
              }
              this.queryDataOfResource();
            }
          }
        })
        .catch((err) => {});
    },

    //判断标签页是否已经打开
    isIncludes(value) {
      // 当点击新增时value为editpage,
      return (
        this.tabitem.filter((val) => {
          return [value].includes(val.name);
        }).length > 0
      );
    },

    //多图上传
    imgsUploaded(res) {
      // res:image的url,name则为'image'
      //  this.storeImg.push(this.formDataC.images)
      this.storeImg.push(res);
      this.$set(this.form, "images", this.storeImg);
    },

    //多图删除
    imgsRemoved(items, index) {
      this.form.images.splice(index, 1);
    },

    //单图上传
    imgUploaded(res, name, source) {
      if (source == "styleEdit") {
        this.$set(this.styleEditForm, name, res.path);
      } else {
        this.$set(this.form, name, res.path);
      }
      //  this.form[name] = res.path
    },
    //单图删除
    imgRemoved(name, source) {
      if (source == "styleEdit") {
        this.styleEditForm[name] = "";
      } else {
        this.form[name] = "";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.radio {
  line-height: 25px;
  position: absolute;
  margin-left: 80px;
}
.hotel_config {
  min-width: 370px;
  margin-top: 15px;
  display: flex;
  margin-left: 28%;
  margin-right: 0%;
  .el-form-item {
    display: flex;
    align-items: center;
    /deep/.el-form-item__content {
      width: 120px;
    }
    &:nth-child(1) {
      margin-left: -55px;
      /deep/.el-form-item__content {
        width: 160px;
      }
    }
    &:nth-child(2) {
      margin-left: 20px;
    }
  }
}

@media (max-width: 1280px) {
  // .hotel_config{
  //   margin-right: 1.5%;
  // }
  // .search{
  //   margin-right: -1%;
  // }
}

.search {
  min-width: 340px;
}

/deep/.el-tabs--border-card > .el-tabs__content {
  padding: 0 !important;
  // padding-top: 14px !important;
  padding-left: 15px !important;
  // padding-right: 15px !important;
}

/deep/.el-tabs--border-card > .el-tabs__content #pane-sjy {
  padding-right: 15px !important;
}

/deep/.search .el-button--mini {
  position: relative;
  left: 10px;
}

.top-box {
  margin-top: 15px;
}
</style>